import "./App.css";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import { Timeline } from "gsap/gsap-core";

function App() {
  useGSAP(() => {
    gsap.set(".fade-in-up", { y: 40, opacity: 0 }); // <-- automatically reverted
    const tl = new Timeline();

    tl.to(".fade-in-up", {
      y: 0,
      opacity: 1,
      ease: "power4.Out",
      stagger: 0.15,
    });
  });

  return (
    <div className="App bg-[#F0EEE9] min-h-screen flex flex-col relative">
      <a
        className="text-xs lg:text-base type-sans underline mt-4"
        href="mailto:info@studiotheus.com"
      >
        info@studiotheus.com
      </a>

      <div className="text-container lg:pt-56 pt-14">
        <div className="hungarian-title type-sans leading-5 lg:leading-[52px] lg:text-[44px] fade-in-up">
          Fejlesztés alatt{" "}
          <span className="dots">
            <span className="dot">.</span>
            <span className="dot">.</span>
            <span className="dot">.</span>
          </span>
        </div>
        <div className="english-title type-serif leading-5 lg:leading-[52px] tracking-[-0.02em] lg:text-[44px] fade-in-up">
          Under construction
          <span className="dots">
            <span className="dot">.</span>
            <span className="dot">.</span>
            <span className="dot">.</span>
          </span>
        </div>
      </div>
      <img
        className="fade-in-up mt-8 lg:mt-36 lg:px-3 px-[10px]"
        src="/studio-theus-logo.svg"
        alt="Studio Theus logo"
      />

      <div className="fade-in-up w-full h-full grow relative">
        <img
          className="mt-4 lg:mt-6 absolute lg:static inset-0 h-full w-full object-cover"
          src="/studio-theus.png"
          alt="Founders of Studio Theus"
        />
      </div>
    </div>
  );
}

export default App;
